<template>
  <section>
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <div class="item-container">
      <div class="item"
           v-for="d in data"
           :key="d.index">
        <div class="cell"
             v-for="cell in d.item"
             :key="cell.index">
          <div class="people">
            <p class="user">
              <span class="info">{{cell.info}}</span>:
              <span class="name">{{cell.name}}</span>
            </p>
            <p class="address">地区:{{cell.address}}
              <span class="iconfont icon-gengduo"
                    style="float: right"></span>
            </p>
            <p class="phone">联系电话:{{cell.phone}}</p>
          </div>
        </div>ff
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: 'detailPage',
  data () {
    return {
      data: [],
      title: '案件相关人详细信息'
    }
  },
  beforeMount () {
    console.log(this.$store.state.knowledge.detailArr)
    console.log(this.$store.state.detailArr)
    this.data = this.$store.state.knowledge.detailArr
    console.log(this.data)
    // console.log(this.data[0].item)
  },
  destroyed () {
    this.$store.commit('refreshDetail')
  }
}

</script>

<style scoped>
.people {
  margin: 10px;
  border: 1px solid #eeeeee;
}
.people p {
  margin: 5px;
}
span.info,
span.iconfont {
  color: #1488ff;
}
.item {
  border: solid 1px #1488ff;
  margin: 0 20px;
  margin-top: 50px;

  border-radius: 10px;
}
</style>
