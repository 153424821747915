var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c("fb-button", {
            attrs: {
              slot: "right",
              icon: "menu",
              size: "small",
              type: "primary",
            },
            slot: "right",
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "item-container" },
        _vm._l(_vm.data, function (d) {
          return _c(
            "div",
            { key: d.index, staticClass: "item" },
            [
              _vm._l(d.item, function (cell) {
                return _c("div", { key: cell.index, staticClass: "cell" }, [
                  _c("div", { staticClass: "people" }, [
                    _c("p", { staticClass: "user" }, [
                      _c("span", { staticClass: "info" }, [
                        _vm._v(_vm._s(cell.info)),
                      ]),
                      _vm._v(": "),
                      _c("span", { staticClass: "name" }, [
                        _vm._v(_vm._s(cell.name)),
                      ]),
                    ]),
                    _c("p", { staticClass: "address" }, [
                      _vm._v("地区:" + _vm._s(cell.address) + " "),
                      _c("span", {
                        staticClass: "iconfont icon-gengduo",
                        staticStyle: { float: "right" },
                      }),
                    ]),
                    _c("p", { staticClass: "phone" }, [
                      _vm._v("联系电话:" + _vm._s(cell.phone)),
                    ]),
                  ]),
                ])
              }),
              _vm._v("ff "),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }